<template>
    <v-app id="inspire">
        <loader></loader>
        <router-view></router-view>
    </v-app>
</template>

<script>
    import Loader from "./components/Loader.vue";
    import http from "./http-common";
    import { mapState } from "vuex";
    import EventBus from './event-bus.js';

    export default {
        name: 'App',
        components: {
            Loader
        }, 
        computed: {
            ...mapState(["isLoading", "refCount"]),
        },
        created() {
           this.enableInterceptor()
        },
        data: () => ({
           // isLoading: false,
            axiosRequestInterceptor: null,
            axiosResponseInterceptor: null,
            axiosTokenHeader: null,
        }),
        mounted() {
            //As an ES6 module
            EventBus.$on("enableInterceptor", () => {
                this.enableInterceptor();
            });

            //As an ES6 module
            EventBus.$on("disableInterceptor", () => {
                this.disableInterceptor();
            });
        },
        methods: {
            enableInterceptor() { 
                this.axiosRequestInterceptor = http.interceptors.request.use(
                    (config) => {
                        this.$store.commit("loading", true);
                        return config;
                    },
                    (error) => {
                        this.$store.commit("loading", false);
                        return Promise.reject(error);
                    }
                );

                this.axiosResponseInterceptor =  http.interceptors.response.use(
                    (response) => {
                        this.$store.commit("loading", false);
                        return response;
                    },
                    (error) => {
                        this.$store.commit("loading", false);
                        return Promise.reject(error);
                    }
                );
            },

            disableInterceptor() { 
                http.interceptors.request.eject(this.axiosRequestInterceptor);
                http.interceptors.response.eject(this.axiosResponseInterceptor);
                
            },

            postTokenHeader(token) { 
                this.axiosTokenHeader = http.defaults.headers.post['Token'] = token;
            },
            deleteTokenHeader() {
                http.defaults.headers.eject(this.axiosTokenHeader);
            },
        },


    };


</script>

