import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default new Router({
    mode: "history",
    hashbang: false,
    routes: [
        {
            path: "/proprio",
            alias: "/proprio",
            name: "proprio",
            component: () => import("./fluxos/Proprio.vue")
        },
        {
            path: "/maior18",
            alias: "/maior18",
            name: "maior18",
            component: () => import("./fluxos/Maior18.vue")
        },

        {
            path: "/menor18",
            alias: "/menor18",
            name: "menor18",
            component: () => import("./fluxos/Menor18.vue")
        },
        {
            path: "/convidadomaior18",
            alias: "/convidadomaior18",
            name: "convidadomaior18",
            component: () => import("./fluxos/ConvidadoMaior18.vue")
        },

        {
            path: "/convidadomenor18",
            alias: "/convidadomenor18",
            name: "convidadomenor18",
            component: () => import("./fluxos/ConvidadoMenor18.vue")
        },
        {
            path: "/abertura",
            alias: "/abertura",
            name: "abertura",
            component: () => import("./fluxos/Abertura.vue")
        }, 
        {
            path: "/propriodpub",
            alias: "/propriodpub",
            name: "propriodpub",
            component: () => import("./fluxos/ProprioDemaisPublicos.vue")
        },
    ]
});