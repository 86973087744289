import Vue from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import router from './router.js';
import store from './store.js';
//import "./plugins/vuetify-mask.js";
//import VueCryptojs from 'vue-cryptojs';
import moment from 'moment';
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
//Vue.use(VueCryptojs)
Vue.use(Vuetify)
Vue.prototype.moment = moment
Vue.config.productionTip = true;

const options = {
    confirmButtonColor: '#316cf5',
    cancelButtonColor: '#e9ecef',
};

Vue.use(VueSweetalert2, options);


String.prototype.initCap = function () {
    return this.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
        return m.toUpperCase();
    });
};

String.prototype.capitalize = function () {
    return (this == null || this == "") ? this : this.toUpperCase();
};

String.prototype.BRCPF = function () {
    var documentId = this;
    documentId = typeof documentId != 'string' ? documentId.toString() : documentId;
    documentId = documentId.padStart(11, '0');
    documentId = documentId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return documentId;
};
String.prototype.BRCNPJ = function () {
    var documentId = this;
    documentId = typeof documentId != 'string' ? documentId.toString() : documentId;
    documentId = documentId.padStart(14, '0');
    documentId = documentId.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return documentId;
};

Date.prototype.BRDate = function () {
    return moment(this, "DD/MM/YYYY")
};

 


new Vue({
    store,
    vuetify: new Vuetify({
        theme: {
            light: true,
            themes: {
                light: {
                    // Color is applied to selected drop down item
                    primary: '#000000',
                }
            }
        }
    }),
    router,
    render: (h) => h(App),
}).$mount('#app');
