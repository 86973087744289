import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        refCount: 0,
        isLoading: false,
        smscode: null,
        questionario: {
            questao01: 0, 
            questao02: 0, 
            questao03: 0, 
            questao04: 0, 
            questao05: 0, 
            questao06: 0, 
        },
        token: null,
        pessoaCorporativo: null,
        userIP: null,
        grausParentesco: null,
        parentesco: null,
        telefoneSMS: null,
        tiposRepresentacao: null,
        patrocinado: null,
        stepTracker: null,
        representanteBaseline: null,
        empresasDesconto:null

    },
    mutations: {
        loading(state, isLoading) {
           // console.log({ isLoading })
            if (isLoading) {
                state.refCount++;
                state.isLoading = true;
            } else if (state.refCount > 0) {
                state.refCount--;
                state.isLoading = (state.refCount > 0);
            }
        },
        saveQuestionario(state, questionario) {
            state.questionario = questionario;
        },
        saveSMSCode(state, code) {
            state.smscode = code;
        },
        saveToken(state, token) {
            state.token = token;
        },
        savePessoaCorporativo(state, pessoaCorporativo) {
            state.pessoaCorporativo = pessoaCorporativo;
        },
        saveUserIP(state, userip) {
            state.userIP = userip;
        },
        saveGrausParentesco(state, grausParentesco) {
            state.grausParentesco = grausParentesco;
        },
        saveTiposRepresentacao(state, tiposRepresentacao) {
            state.tiposRepresentacao = tiposRepresentacao;
        },
        
        saveParentesco(state, parentesco) {
            state.parentesco = parentesco;
        },
        saveTelefoneSMS(state, telefone) {
            state.telefoneSMS = telefone;
        },
        savePatrocinado(state, patrocinado) {
            state.patrocinado = patrocinado;
        },
        saveStepTracker(state, stepTracker) {
            state.stepTracker = stepTracker;
        },
        saveRepresentanteBaseline(state, representanteBaseline) {
            state.representanteBaseline = representanteBaseline;
        },
        saveEmpresasDesconto(state, empresasDesconto) {
            state.empresasDesconto = empresasDesconto;
        },
        
        
    }
})